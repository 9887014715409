<template>
  <div>
    <p class="alert-danger p-3 text-center fw-bold">後台人員專屬</p>
    <!-- 發布到 line notify 下單連結位置 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">發布到 Fb 下單連結位置</p>
      <div class="select-radio">
        <label v-for="data in placeOnOrderUrlPosition" :key="data.id">
          <input
            type="radio"
            name="rows"
            :checked="data.selected"
            @click="
              updateConfiguration('p_fb', 'placeOnOrderUrlPosition', data)
            "
          />
          <span class="radio-style" v-if="data.value === 'd4'">預設</span>
          <span class="radio-style" v-else-if="data.value === 'bottom'"
            >最下面</span
          >
          <span class="radio-style" v-else-if="data.value === 'none'"
            >不放</span
          >
        </label>
      </div>
    </div>
    <!-- 切換同步留言版本 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">發布到 Fb 下單連結位置</p>
      <div class="select-radio">
        <label v-for="data in syncCommentsType" :key="data.id">
          <input
            type="radio"
            name="syncCommentsType"
            :checked="data.selected"
            @click="
              updateConfiguration('p_fb', 'syncCommentsType', data)
            "
          />
          <span class="radio-style" v-if="data.value === 'old'">舊版</span>
          <span class="radio-style" v-else-if="data.value === 'new'">新版</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 此頁面試給後台人員用的 (目的是要可以控制該賣場同步留言方式是要用新版還是舊版)
  data() {
    return {}
  },
  created() {
    this.getPrefer()
  },
  computed: {
    placeOnOrderUrlPosition() {
      return this.$store.state.Prefer.p_fb.placeOnOrderUrlPosition
    },
    syncCommentsType() {
      return this.$store.state.Prefer.p_fb.syncCommentsType
    }
  },
  methods: {
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(category, item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category,
        item: item,
        data: data
      })
    },
  },
}
</script>